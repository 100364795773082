import posthog from 'posthog-js'

export default {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog.init(
      'phc_adeZw1Mxiu5hm9kjZoCega3zunxWqhPDJZaTtNyPTLO',
      {
        api_host: 'https://app.posthog.com',
      },
    )
  },
}
