import axios from '../../libs/axios'

const state = {
  configurations: [],
  configuration: null,
}

const getters = {
  configurations: state => state.configurations,
  configuration: state => state.configuration,
}

const actions = {
  async fetchConfigurations({ commit }) {
    const response = await axios.get('/configurations/')
    commit('setConfigurations', response.data.results)
  },
  async fetchConfiguration({ commit }, id) {
    const response = await axios.get(`/configurations/${id}/`)
    commit('setConfiguration', response.data)
    return response.data
  },
  async createConfiguration({ commit }, payload) {
    const response = await axios.post('/configurations/', payload)
    commit('addConfiguration', response.data)
  },
  async updateConfiguration({ commit }, payload) {
    const response = await axios.put(`/configurations/${payload.key}/`, payload)
    commit('updateConfiguration', response.data)
  },
  async deleteConfiguration({ commit }, id) {
    await axios.delete(`/configurations/${id}/`)
    commit('removeConfiguration', id)
  },
}

const mutations = {
  setConfigurations: (state, items) => (state.configurations = items),
  setConfiguration: (state, item) => (state.configuration = item),
  addConfiguration: (state, item) => state.configurations.push(item),
  updateConfiguration: (state, item) => {
    const index = state.configurations.findIndex(i => i.id === item.id)
    state.configurations.splice(index, 1, item)
    state.configuration = item
  },
  removeConfiguration: (state, id) => {
    const index = state.configurations.findIndex(i => i.id === id)
    state.configurations.splice(index, 1)
    state.configuration = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
