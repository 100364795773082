import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    orderData: null,
    ordersList: [],
    currentPage: null,
    avisos: [],
    ordersExcel: null,
    filters: {
      page: 1,
      pageSize: 100,
      search: null,
      startDate: null,
      endDate: null,
      status: [
        'CREATED',
        'RESERVED',
        'PAID',
        'SENT',
      ],
    },
    salesSummary: [],
    statusesList: [
      {
        text: 'Pedido realizado',
        value: 'CREATED',
      },
      {
        text: 'Reservado, esperando compensação',
        value: 'RESERVED',
      },
      {
        text: 'Pago',
        value: 'PAID',
      },
      {
        text: 'Enviado',
        value: 'SENT',
      },
      {
        text: 'Entregue',
        value: 'DELIVERED',
      },
      {
        text: 'Estornado',
        value: 'REFUNDED',
      },
      {
        text: 'Cancelado',
        value: 'CANCELED',
      },
    ],
    statusFilters: {
      new: [
        'CREATED',
      ],
      'paid-or-reserved': [
        'RESERVED',
        'PAID',
      ],
      sent: [
        'SENT',
      ],
    },
  },
  getters: {
    ordersList: state => state.ordersList,
    currentPage: state => state.currentPage,
    filters: state => state.filters,
    salesSummary: state => state.salesSummary,
    statusesList: state => state.statusesList,
    statusFilters: state => state.statusFilters,
    avisos: state => state.avisos,
  },
  mutations: {
    onGetAvisos(state, avisos) {
      state.avisos = avisos
    },
    updateOrderData(state, objNew) {
      const objIndex = state.ordersList.findIndex((obj => obj.id == objNew.id))
      state.ordersList[objIndex].product_choices = objNew.product_choices
      state.ordersList[objIndex].shippingchoice = objNew.shippingchoice
    },
    firstPage(state) {
      state.filters.page = 1
    },
    updateFilters(state, val) {
      state.filters = {
        ...state.filters,
        ...val,
      }
    },
    onGetOrders(state, val) {
      state.ordersList = val.results
      state.totalCount = val.count
    },
    onGetOrderByID(state, val) {
      state.orderData = val
    },
    onGetSalesDashboard(state, val) {
      state.salesSummary = val
    },
    filtersAll(state) {
      state.filters = {
        ...state.filters,
        page: 1,
        search: null,
        startDate: null,
        endDate: null,
        status: [],
      }
    },
    onGetOrdersExcel(state, val) {
      state.ordersExcel = val
    },
  },
  actions: {
    downloadOrders({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/orders/excel/', {
            responseType: 'blob',
          })
          .then(response => {
            commit('onGetOrdersExcel', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOrders({ state, commit }, filters) {
      commit('updateFilters', filters)
      const { page } = state.filters
      const { pageSize } = state.filters
      const search = state.filters.search ? state.filters.search : ''
      const start = state.filters.startDate ? state.filters.startDate : ''
      const end = state.filters.endDate ? state.filters.endDate : ''
      const statuses = state.filters.status && state.filters.status.length > 0 ? `&status=${state.filters.status.join('&status=')}` : ''
      return new Promise((resolve, reject) => {
        axios
          .get(`/orders/?page=${page}&page_size=${pageSize}&search=${search}${statuses}&start=${start}&end=${end}`)
          .then(response => {
            commit('onGetOrders', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    getOrderById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orders/${id}/`)
          .then(response => {
            commit('onGetOrderByID', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createOrUpdateOrder({ state, commit }, order) {
      return new Promise((resolve, reject) => {
        if (order.id) {
          axios
            .put(`/orders/${order.id}/`, order)
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        } else {
          axios
            .post('/orders/create/', order)
            .then(response => {
              // commit('onGetNewOrder', response.data)
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    orderStatusUpdate({ state, commit }, { status, ordersIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/status-update/', {
            status,
            orders_id: ordersIds,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateNFe({ state, commit }, { orderID, nfe }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/update-nfe/', {
            order_id: orderID,
            nfe_number: nfe,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateTrackingNumber({ state, commit }, { orderID, trackingNumber }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/update-tracking/', {
            order_id: orderID,
            tracking_number: trackingNumber,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateJadlog({ state, commit }, orderID) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/update-jadlog/', {
            order_id: orderID,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateContaAzul({ state, commit }, orderID) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/update-contaazul/', {
            order_id: orderID,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getSalesDashboard({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/dash/')
          .then(response => {
            commit('onGetSalesDashboard', response.data)
            resolve()
          })
          .catch(() => {
            dispatch('user/logout', {}, { root: true })
          })
      })
    },
    requestReport({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/request-report/')
          .then(response => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    getAvisos({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/orders/availability-notifications/')
          .then(response => {
            commit('onGetAvisos', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
  },
}
