import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import users from './users'
import orders from './orders'
import products from './products'
import dashboard from './dashboard'
import discounts from './discounts'
import attributes from './attributes'
import whatsapp from './whatsapp'
import configurations from './configurations'
import shipping from './shipping'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    user: state.user,
  }),
  filter: mutation => [
    'user/onLogin',
    'user/onLogout',
  ].includes(mutation.type),
})

export default new Vuex.Store({
  modules: {
    user,
    orders,
    products,
    dashboard,
    users,
    app,
    appConfig,
    verticalMenu,
    discounts,
    attributes,
    whatsapp,
    configurations,
    shipping,
  },
  plugins: [vuexLocal.plugin],
})
