export default [
  {
    path: '/orders',
    name: 'orders-list',
    component: () => import('@/views/ecoms/orders/OrdersList.vue'),
    meta: {
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/orders/filter/:filter',
    name: 'orders-list-filtered',
    component: () => import('@/views/ecoms/orders/OrdersList.vue'),
    props(route) {
      const props = { ...route.params }
      props.filter = props.filter
      return props
    },
    meta: {
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/orders/create',
    name: 'order-create',
    component: () => import('@/views/ecoms/orders/OrderEdit.vue'),
    meta: {
      pageTitle: 'Novo Pedido',
    },
  },
  {
    path: '/orders/:id',
    name: 'order-edit',
    component: () => import('@/views/ecoms/orders/OrderEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.orderId = props.id
      return props
    },
    meta: {
      pageTitle: 'Edição Pedido',
    },
  },
]
