import axios from '../../libs/axios'

const state = {
  messageTemplates: [],
  MessageTemplate: null,
}

const getters = {
  getMessageTemplates: state => state.messageTemplates,
  getMessageTemplate: state => state.messageTemplate,
}

const actions = {
  async fetchMessageTemplates({ commit }) {
    const response = await axios.get('/communication/whatsapp-templates/')
    commit('setItems', response.data.results)
  },
  async fetchMessageTemplate({ commit }, id) {
    const response = await axios.get(`/communication/whatsapp-templates/${id}/`)
    commit('setItem', response.data)
  },
  async createItem({ commit }, payload) {
    const response = await axios.post('/communication/whatsapp-templates/', payload)
    commit('addItem', response.data)
  },
  async updateItem({ commit }, payload) {
    const response = await axios.put(`/communication/whatsapp-templates/${payload.id}/`, payload)
    commit('updateItem', response.data)
  },
  async deleteItem({ commit }, id) {
    await axios.delete(`/communication/whatsapp-templates/${id}/`)
    commit('removeItem', id)
  },
}

const mutations = {
  setItems: (state, items) => (state.messageTemplates = items),
  setItem: (state, item) => (state.messageTemplate = item),
  addItem: (state, item) => state.messageTemplates.push(item),
  updateItem: (state, item) => {
    const index = state.messageTemplates.findIndex(i => i.id === item.id)
    state.items.splice(index, 1, item)
    state.messageTemplate = item
  },
  removeItem: (state, id) => {
    const index = state.messageTemplates.findIndex(i => i.id === id)
    state.messageTemplates.splice(index, 1)
    state.messageTemplate = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
