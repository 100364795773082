import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    userData: null,
    usersList: [],
    currentPage: null,
    filters: {
      page: 1,
      pageSize: 100,
    },
  },
  getters: {
    usersList: state => state.usersList,
    currentPage: state => state.currentPage,
    filters: state => state.filters,
  },
  mutations: {
    firstPage(state) {
      state.filters.page = 1
    },
    updateFilters(state, val) {
      state.filters = {
        ...state.filters,
        ...val,
      }
    },
    onGetUsers(state, val) {
      state.usersList = val.results
      state.totalCount = val.count
    },
    onGetOrderByID(state, val) {
      state.userData = val
    },
    filtersAll(state) {
      state.filters = {
        ...state.filters,
        page: 1,
        search: null,
      }
    },
  },
  actions: {
    getUsers({ state, commit }, filters) {
      commit('updateFilters', filters)
      const { page } = state.filters
      const { pageSize } = state.filters
      const search = state.filters.search ? state.filters.search : ''
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/?page=${page}&page_size=${pageSize}&search=${search}`)
          .then(response => {
            commit('onGetUsers', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    getAddresses({ state, commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/addresses/?page_size=8&search=${search}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOrderById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orders/${id}/`)
          .then(response => {
            commit('onGetOrderByID', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    // createOrUpdateOrder({ state, commit }, order) {
    //   return new Promise((resolve, reject) => {
    //     if (order.id) {
    //       axios
    //         .put(`/orders/${order.id}/`, order)
    //         .then(response => {
    //           resolve(response)
    //         })
    //         .catch(error => reject(error))
    //     } else {
    //       axios
    //         .post('/orders/create/', order)
    //         .then(response => {
    //           // commit('onGetNewOrder', response.data)
    //           resolve(response)
    //         })
    //         .catch(error => reject(error))
    //     }
    //   })
    // },
  },
}
