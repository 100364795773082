export default [
  {
    path: '/discounts',
    name: 'discounts-list',
    component: () => import('@/views/ecoms/discounts/DiscountList.vue'),
    meta: {
      pageTitle: 'Descontos',
    },
  },
  {
    path: '/discounts/create',
    name: 'discount-create',
    component: () => import('@/views/ecoms/discounts/DiscountEdit.vue'),
    meta: {
      pageTitle: 'Novo Cupom',
    },
  },
  {
    path: '/discounts/:id',
    name: 'discount-edit',
    component: () => import('@/views/ecoms/discounts/DiscountEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.discountId = props.id
      return props
    },
    meta: {
      pageTitle: 'Edição Cupom',
    },
  },
]
