export default [
  {
    path: '/users',
    name: 'users-list',
    component: () => import('@/views/ecoms/users/UsersList.vue'),
    meta: {
      pageTitle: 'Usuários',
    },
  },
]
