import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    productData: null,
    productsList: [],
    categoriesList: [],
    texturesList: [],
    nationalitiesList: [],
    colorsList: [],
    collectorTypesList: [],
    accessoryTypesList: [],
    currentPage: null,
    favorites: [],
    productsExcel: null,
    filters: {
      page: 1,
      pageSize: 100,
    },
    totalCount: null,
  },
  getters: {
    productData: state => state.productData,
    productsList: state => state.productsList,
    categoriesList: state => state.categoriesList,
    texturesList: state => state.texturesList,
    nationalitiesList: state => state.nationalitiesList,
    colorsList: state => state.colorsList,
    collectorTypesList: state => state.collectorTypesList,
    accessoryTypesList: state => state.accessoryTypesList,
    currentPage: state => state.currentPage,
    filters: state => state.filters,
    pageSize: state => state.pageSize,
    totalCount: state => state.totalCount,
  },
  mutations: {
    updateFilters(state, val) {
      state.filters = {
        ...state.filters,
        ...val,
      }
    },
    onGetProducts(state, val) {
      state.productsList = val.results
      state.totalCount = val.count
    },
    onGetCategories(state, val) {
      state.categoriesList = val
    },
    onGetTextures(state, val) {
      state.texturesList = val
    },
    onGetColors(state, val) {
      state.colorsList = val
    },
    onGetNationalities(state, val) {
      state.nationalitiesList = val
    },
    onGetCollectorTypes(state, val) {
      state.collectorTypesList = val
    },
    onGetAccessoryTypes(state, val) {
      state.accessoryTypesList = val
    },
    onGetProductByID(state, val) {
      state.productData = val
    },
    onGetProductsExcel(state, val) {
      state.productsExcel = val
    },
    onGetFavorites(state, val) {
      state.favorites = val
    },
  },
  actions: {
    downloadProducts({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/excel/', {
            responseType: 'blob',
          })
          .then(response => {
            commit('onGetProductsExcel', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getFavorites({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/favorites/')
          .then(response => {
            commit('onGetFavorites', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    createOrUpdateProduct({ state, commit }, product) {
      return new Promise((resolve, reject) => {
        if (product.id) {
          axios
            .put(`/products/${product.id}/`, product)
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        } else {
          axios
            .post('/products/create/', product)
            .then(response => {
              // commit('onGetNewOrder', response.data)
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    sendNotification({ state, commit }, productId) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/notify/', { product_id: productId })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    getProducts({ state, commit }, filters) {
      commit('updateFilters', filters)
      const { page } = state.filters
      const { pageSize } = state.filters
      const search = state.filters.search ? state.filters.search : ''
      return new Promise((resolve, reject) => {
        axios
          .get(`/products/?page=${page}&page_size=${pageSize}&search=${search}`)
          .then(response => {
            commit('onGetProducts', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    getCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/categories/')
          .then(response => {
            commit('onGetCategories', response.data)
            resolve(response)
          })
      })
    },
    getTextures({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/textures/')
          .then(response => {
            commit('onGetTextures', response.data)
            resolve(response)
          })
      })
    },
    getColors({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/colors/')
          .then(response => {
            commit('onGetColors', response.data)
            resolve(response)
          })
      })
    },
    getNationalities({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/nationalities/')
          .then(response => {
            commit('onGetNationalities', response.data)
            resolve(response)
          })
      })
    },
    getCollectorTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/collector-types/')
          .then(response => {
            commit('onGetCollectorTypes', response.data)
            resolve(response)
          })
      })
    },
    getAccessoryTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/accessory-types/')
          .then(response => {
            commit('onGetAccessoryTypes', response.data)
            resolve(response)
          })
      })
    },
    updatePublish(context, { status, productIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/update-published/', {
            new_status: status,
            products_id: productIds,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateSales(context, { status, productIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/update-sales/', {
            new_status: status,
            products_id: productIds,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateDestaque(context, { status, productIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/update-featured/', {
            new_status: status,
            products_id: productIds,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateGoogle(context, productIds) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/update-google-shopping/', {
            products_id: productIds,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    duplicateProducts(context, productIds) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/duplicate/', {
            products_id: productIds,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getProductById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/products/${id}/`)
          .then(response => {
            commit('onGetProductByID', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    updateStock(context, {
      productId, quantity, date, reason,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orders/stock-update/', {
            product: productId,
            quantity,
            date,
            reason,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
