import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    discountData: null,
    discountsList: [],
    currentPage: null,
    filters: {
      page: 1,
      pageSize: 100,
      search: null,
      startDate: null,
      endDate: null,
    },
    salesSummary: [],
    statusesList: [
      {
        text: 'Pedido realizado',
        value: 'CREATED',
      },
      {
        text: 'Reservado, esperando compensação',
        value: 'RESERVED',
      },
      {
        text: 'Pago',
        value: 'PAID',
      },
      {
        text: 'Enviado',
        value: 'SENT',
      },
      {
        text: 'Entregue',
        value: 'DELIVERED',
      },
      {
        text: 'Estornado',
        value: 'REFUNDED',
      },
      {
        text: 'Cancelado',
        value: 'CANCELED',
      },
    ],
  },
  getters: {
    discountsList: state => state.discountsList,
    currentPage: state => state.currentPage,
    filters: state => state.filters,
    salesSummary: state => state.salesSummary,
    statusesList: state => state.statusesList,
  },
  mutations: {
    firstPage(state) {
      state.filters.page = 1
    },
    updateFilters(state, val) {
      state.filters = {
        ...state.filters,
        ...val,
      }
    },
    onGetDiscounts(state, val) {
      state.discountsList = val.results
      state.totalCount = val.count
    },
    onGetDiscountByID(state, val) {
      state.discountData = val
    },
    filtersAll(state) {
      state.filters = {
        ...state.filters,
        page: 1,
        search: null,
        startDate: null,
        endDate: null,
        status: [],
      }
    },
  },
  actions: {
    getDiscounts({ state, commit }, filters) {
      commit('updateFilters', filters)
      const { page } = state.filters
      const { pageSize } = state.filters
      const code = state.filters.search ? state.filters.search : ''
      return new Promise((resolve, reject) => {
        axios
          .get(`/payments/discounts/?page=${page}&page_size=${pageSize}&code=${code}`)
          .then(response => {
            commit('onGetDiscounts', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    getDiscountById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payments/discounts/${id}/`)
          .then(response => {
            commit('onGetDiscountByID', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createOrUpdateDiscount({ state, commit }, discount) {
      return new Promise((resolve, reject) => {
        if (discount.id) {
          axios
            .put(`/payments/discounts/${discount.id}/`, discount)
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        } else {
          axios
            .post('/payments/discounts/create/', discount)
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        }
      })
    },
    deleteDiscount({ state, commit }, discountId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/payments/discounts/${discountId}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
