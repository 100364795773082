import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    attributeTypes: [],
    productAttribute: {},
    productAttributes: [],
    productAttributesAll: [],
  },
  getters: {
    attributeTypes: state => state.attributeTypes,
    productAttributes: state => state.productAttributes,
    productAttributesAll: state => state.productAttributesAll,
    productAttribute: state => state.productAttribute,
  },
  mutations: {
    onGetAttributeTypes(state, val) {
      state.attributeTypes = val
    },
    onGetAttribute(state, val) {
      state.productAttribute = val
    },
    onGetProductAttributes(state, val) {
      state.productAttributes = val
    },
    onGetProductAttributesAll(state, val) {
      state.productAttributesAll = val
    },
  },
  actions: {
    getAttributeTypes({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/attribute-types/')
          .then(response => {
            commit('onGetAttributeTypes', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    getAttributeTypeById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/products/attribute-types/${id}/`)
          .then(response => {
            commit('onGetAttribute', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    updateAttributeTypeById({ state, commit }, attribute) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/products/attribute-types/${attribute.id}/`, attribute)
          .then(response => {
            commit('onGetAttribute', response.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    },
    createAttributeTypes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/products/attribute-types/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAttributeType({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/products/attribute-types/${id}/delete/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductAttributes({ commit }, attributeTypeId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/products/attribute-types/${attributeTypeId}/product-attributes/`)
          .then(response => {
            commit('onGetProductAttributes', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getProductAttributesAll({ commit }, attributeTypeId) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products/product-attributes/')
          .then(response => {
            commit('onGetProductAttributesAll', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createProductAttribute({ commit }, { attributeTypeId, payload }) {
      return new Promise((resolve, reject) => {
        axios.post(`/products/attribute-types/${attributeTypeId}/product-attributes/`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteproductAttibute({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/products/product-attributes/${id}/delete/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
