export default [
  {
    path: '/products',
    name: 'products-list',
    component: () => import('@/views/ecoms/products/ProductsList.vue'),
    meta: {
      pageTitle: 'Produtos',
    },
  },
  {
    path: '/products/favorites',
    name: 'favorites-list',
    component: () => import('@/views/ecoms/products/Favorites.vue'),
    meta: {
      pageTitle: 'Favoritos',
    },
  },
  {
    path: '/products/create',
    name: 'product-create',
    component: () => import('@/views/ecoms/products/ProductEdit.vue'),
    meta: {
      pageTitle: 'Novo Produto',
    },
  },
  {
    path: '/products/:id',
    name: 'product-edit',
    component: () => import('@/views/ecoms/products/ProductEdit.vue'),
    props(route) {
      const props = { ...route.params }
      props.productId = props.id
      return props
    },
    meta: {
      pageTitle: 'Edição Produto',
    },
  },
]
