import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    monthlySales: [],
    ordersCount: {},
    favorites: [],
    notifyMe: [],
  },
  getters: {
    monthlySales: state => state.monthlySales,
    ordersCount: state => state.ordersCount,
    favorites: state => state.favorites,
    notifyMe: state => state.notifyMe,
  },
  mutations: {
    onGetFullDashboard(state, val) {
      state.monthlySales = val.monthly_sales
      state.ordersCount = val.orders_count
      state.favorites = val.favorites
      state.notifyMe = val.notify_me
    },
  },
  actions: {
    getFullDashboard({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/dash/')
          .then(response => {
            commit('onGetFullDashboard', response.data)
            resolve(response)
          })
          .catch(error => {
            dispatch('user/logout', {}, { root: true })
            reject(error)
          })
      })
    },
  },
}
