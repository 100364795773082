import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
    accounts: [],
    account: null,
  },
  getters: {
  },
  mutations: {

  },
  actions: {
    listAccountMelhorEnvio({ commit, state }) {
      state.loading = true
      return new Promise((resolve, reject) => {
        axios.get('shipping/melhorenvio/')
          .then(response => {
            state.accounts = response.data.results
            state.loading = false
            resolve(response)
          })
          .catch(error => {
            state.loading = false
            reject(error)
          })
      })
    },
    refreshTokenMelhorEnvio({ commit, state }) {
      state.loading = true
      return new Promise((resolve, reject) => {
        axios.post('shipping/refresh-token/')
          .then(response => {
            state.loading = false
            state.account = response.data
            resolve(response)
          })
          .catch(error => {
            state.loading = false
            state.account = null
            reject(error)
          })
      })
    },
    getConnectionURLMelhorEnvio({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.post('shipping/connect/')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    disconnectMelhorEnvio({ commit, state }) {
      state.loading = true
      return new Promise((resolve, reject) => {
        axios.delete(`shipping/melhorenvio/${state.account.id}/`)
          .then(response => {
            state.loading = false
            state.account = null
            resolve(response)
          })
          .catch(error => {
            state.loading = false
            reject(error)
          })
      })
    },
  },
}
