export default [
  {
    path: '/configurations/attribute-types',
    name: 'attribute-types',
    component: () => import('@/views/ecoms/attributes/Attributes.vue'),
    meta: {
      pageTitle: 'Atributos',
    },
  },
  {
    path: '/configurations/attribute-types/:id',
    name: 'product-attributes',
    component: () => import('@/views/ecoms/attributes/ProductAttributes.vue'),
    props(route) {
      const props = { ...route.params }
      props.attributeId = props.id
      return props
    },
    meta: {
      pageTitle: 'Atributos',
    },
  },
  {
    path: '/configurations/whatsapp',
    name: 'whatsapp',
    component: () => import('@/views/ecoms/whatsapp/Whatsapp.vue'),
    meta: {
      pageTitle: 'Whatsapp',
    },
  },
  {
    path: '/configurations/payment-config',
    name: 'payment-config',
    component: () => import('@/views/ecoms/attributes/ProductAttributes.vue'),
    props(route) {
      const props = { ...route.params }
      props.attributeId = props.id
      return props
    },
    meta: {
      pageTitle: 'Atributos',
    },
  },
  {
    path: '/configurations/payment-config/paghiper',
    name: 'paghiper-config',
    component: () => import('@/views/ecoms/payments/Paghiper.vue'),
    meta: {
      pageTitle: 'Paghiper',
    },
  },
  {
    path: '/configurations/payment-config/mercadopago',
    name: 'mercadopago-config',
    component: () => import('@/views/ecoms/payments/MercadoPago.vue'),
    meta: {
      pageTitle: 'MercadoPago',
    },
  },
  {
    path: '/configurations/payment-config/cielo',
    name: 'cielo-config',
    component: () => import('@/views/ecoms/payments/Cielo.vue'),
    meta: {
      pageTitle: 'Cielo',
    },
  },
  {
    path: '/configurations/payment-config/getnet',
    name: 'getnet-config',
    component: () => import('@/views/ecoms/payments/Getnet.vue'),
    meta: {
      pageTitle: 'Getnet',
    },
  },
  {
    path: '/configurations/payment-config/stripe',
    name: 'stripe-config',
    component: () => import('@/views/ecoms/payments/Stripe.vue'),
    meta: {
      pageTitle: 'Stripe',
    },
  },
  {
    path: '/configurations/shipping/melhor-envio',
    name: 'melhor-envio',
    component: () => import('@/views/ecoms/shipping/MelhorEnvio.vue'),
    meta: {
      pageTitle: 'Melhlor Envio',
    },
  },
]
