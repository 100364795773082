import jwtDecode from 'jwt-decode'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'

export default {
  namespaced: true,
  state: {
    userData: null,
  },
  getters: {
    userData: state => state.userData,
  },
  mutations: {
    onLogin(state, payload) {
      state.userData = payload
    },
    onLogout(state) {
      state.userData = null
    },
  },
  actions: {
    login({ state, commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        useJwt.login({
          email,
          password,
        }).then(response => {
          const userData = jwtDecode(response.data.access)
          useJwt.setToken(response.data.access)
          useJwt.setRefreshToken(response.data.refresh)
          commit('onLogin', userData)
          resolve(state.userData.user_id)
        })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout({ commit }) {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      router.push({ name: 'auth-login' })
      commit('onLogout')
      this._vm.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Você não está mais logado',
          icon: 'SlashIcon',
          variant: 'danger',
          text: 'Por favor entre novamente',
        },
      })
    },
  },
}
